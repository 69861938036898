import { useEffect } from "react";
import { dispatchCusEvent, getAutoShortLang, getCHPYParamByName, setCookie } from "../utils/utils";
import useLanguage, { ENUM_LANG_LISTENER, languages } from "./site/useLanguage";
import { useEventListener } from "usehooks-ts";

export default function useCommonHooks() {
  const [, setSelectLang] = useLanguage();

  useEffect(() => {
    const campaignId = getCHPYParamByName("campaign_id");
    if (!campaignId) return;
    // localStorage.setItem("campaign_id", campaignId);

    // refer on server
    // const w_id = getCHPYParamByName("w_id");
    // const m_id = getCHPYParamByName("m_id");
    // const b_id = getCHPYParamByName("b_id");
    // const c_id = getCHPYParamByName("c_id");

    // const referer = w_id || m_id || b_id || c_id;

    // if (!referer) return;

    // document.cookie = encodeURIComponent("referer") + "=" + encodeURIComponent(btoa(referer)) + ";";

    setCookie("campaign_id", campaignId);
  }, []);

  useEffect(() => {
    const lang = getCHPYParamByName("lang");
    if (!lang) return;

    if (lang === "auto") {
      const short = getAutoShortLang();
      const newlang = languages.find((el) => el.code === short)?.lang;

      setSelectLang(newlang);

      return;
    }

    setSelectLang(lang);
  }, []);

  // useEventListener(ENUM_LANG_LISTENER.auto, () => {
  //   const short = getAutoShortLang();
  //   const lang = languages.find((el) => el.code === short)?.lang;

  //   setSelectLang(lang);
  // });
}
