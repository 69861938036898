import { MODEL } from "../components/common/consts";

export const sleep = async (ms) => new Promise((rs, rj) => setTimeout(rs, ms));

// getAutoShortLang - получение языка по браузеру
export const getAutoShortLang = () => {
  const langBrowserFull = navigator.language || navigator?.userLanguage;
  const langMiniBrowser = langBrowserFull.substring(0, 2)?.toLowerCase();
  const langBrowser = langMiniBrowser || "en";
  return langBrowser;
};

//getShortLang - получение языка из локалсторадже
export const getShortLang = () => {
  const code = getCookie("language")?.split("_")?.[0];
  if (code && code !== "undefined") return code;

  const configLang = window.startConfig?.language?.code;
  const serverLang = configLang?.substring(0, 2)?.toLowerCase?.();
  if (serverLang) return serverLang;

  const langBrowser = getAutoShortLang();
  return langBrowser;
};

// setCookie - установка в куки
export function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
  // document.cookie = name + "=" + (value || "") + expires;
}

export const getFormInputs = (children) => {
  const data = {};

  for (let i = 0; i < children.length; i++) {
    const item = children.item(i);
    if (item.type === "radio") {
      if (item.checked) data[item.name] = item.value;
      continue;
    }
    data[item.name] = item.value;
  }
  return data;
};

export const redirectToLk = (type) => {
  window.location.href = type === MODEL ? "/webcam/model/chat" : "/webcam/studio/manager";
};

// getCHPYParamByName -  парсинг параметра по измени из  ЧПУ
export function getCHPYParamByName(name) {
  const pathname = window.location.pathname;
  return pathname.split(name + ":")?.[1]?.split("/")?.[0];
}

// getCookie - получение куки
export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

//dispatchCusEvent - кинуть пользовательское событие
export const dispatchCusEvent = (eventName) => {
  window.dispatchEvent(new CustomEvent(eventName));
};
